import { TimeModeEnum } from 'types/interfaces'
import classes from './GraphDashboardSummary.module.css'
import LargeMetricNumber from './LargeMetricNumber'

const GraphDashboardSummary = (props: {
    metricValue: number
    metricColor: string
    metricTime: string
    utcOffset: number
    timeMode: TimeModeEnum
    station: string
}) => {
    return (
        <div className={classes.summaryRow}>
            <div>
                <LargeMetricNumber value={props.metricValue} color={props.metricColor} />
            </div>
            <div>
                <p className={classes.metricTime}>
                    {props.metricTime}
                </p>
                <p className={classes.metricTime}>
                {props.station} - {TimeModeEnum[props.timeMode]} ({props.utcOffset < 0 ? '' : '+'}{props.utcOffset})
                </p>
            </div>
        </div>
    )
}

export default GraphDashboardSummary
