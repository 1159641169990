import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-react-grid'
import { UpdateMode } from 'hooks/useLocalStorage'
import { orderBy, uniq } from 'lodash'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'

export interface OrderableColumn {
    field?: string
    orderIndex?: number
    hide?: boolean
}

const getOverriddenColumns = (
    storedColumnDefinitions: KendoGridColumn[],
    defaultColumnDefinitions: KendoGridColumn[],
): KendoGridColumn[] => {
    // optionally bring in column settings from local storage, override the defaults
    let mergedColumns: KendoGridColumn[] = [...defaultColumnDefinitions]
    if (storedColumnDefinitions.length > 0) {
        mergedColumns = mergedColumns.filter(
            (x) => x.field === 'selected' || storedColumnDefinitions.find((y) => y.field === x.field),
        )
    }
    storedColumnDefinitions.forEach((columnOverride) => {
        const columnDefinition = mergedColumns.find((x) => x.field === columnOverride.field)
        if (columnDefinition) {
            columnDefinition.orderIndex = columnOverride.orderIndex
            columnDefinition.width = columnOverride.width
            columnDefinition.hide = columnOverride.hide
        }
    })
    return mergedColumns.filter((x) => x.hide !== true)
}

const getMergedColumns = (updatedColumns: KendoGridColumn[], previousColumns: KendoGridColumn[]) => {
    const mergedColumns: KendoGridColumn[] = [...previousColumns].map((col) => {
        const updatedColumn = updatedColumns.find((x) => x.field === col.field)
        if (updatedColumn) {
            return {
                ...col,
                cell: updatedColumn.cell,
                headerCell: updatedColumn.headerCell,
                title: updatedColumn.title,
                headerClassName: updatedColumn.headerClassName,
            }
        }
        return { ...col }
    })

    // may be "tag" columns that come in that aren't part of the hardcoded definitions
    updatedColumns.forEach((col) => {
        if (!mergedColumns.find((x) => x.field === col.field)) {
            mergedColumns.push(col)
        }
    })

    // filter out any Tag columns that have been removed (ie, tag has been removed)
    return mergedColumns.filter((x) => !!updatedColumns.find((y) => y.field === x.field))
}

/**
 * Get fields used in the filter of the grid data state
 * @param dataState
 * @returns
 */
const parseFilterFieldsFromGridDataState = (dataState: State): string[] => {
    if (!dataState.filter) {
        return []
    }
    return getFilterFields(dataState.filter!.filters)
}

const getFilterFields = (filters: (CompositeFilterDescriptor | FilterDescriptor)[]): string[] => {
    const fields: string[] = []
    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i]
        if ('filters' in filter) {
            // is CompositeFilterDescriptor
            fields.push(...getFilterFields(filter.filters))
        } else {
            // bottom-level filter descriptor
            fields.push(filter.field! as string)
        }
    }
    return uniq(fields)
}

const DefaultFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
}

const handleFilterButtonClick = (
    enableFiltering: boolean,
    gridDataState: State,
    setFilteringEnabled: (val: boolean, updateMode: UpdateMode) => void,
    setGridDataState: (state: State, updateMode: UpdateMode) => void,
) => {
    if (enableFiltering) {
        setFilteringEnabled(false, { setState: true })
        setGridDataState({ ...gridDataState, filter: DefaultFilter } as State, {
            setState: true,
            dispatchToOtherComponents: true,
        })
    } else {
        setFilteringEnabled(true, { setState: true })
    }
}

/**
 * Scroll so the given row number (1-based), is in view
 * @param gridRef
 * @param rowNumber
 */
const scrollToRow = (gridRef: React.RefObject<Grid>, rowNumber: number) => {
    if (rowNumber >= 1) {
        // timeout/delay makes this work whilst in a dialog
        setTimeout(() => {
            const allRows: HTMLTableRowElement[] = Array.from(gridRef.current!.element!.querySelectorAll('tr'))
            if (allRows.length > 1) {
                const allRowsAfterHeader = allRows.slice(1)
                allRowsAfterHeader[rowNumber - 1].scrollIntoView({ behavior: 'smooth' })
            }
        })
    }
}

/**
 * Update the order of columns, assuming there is one with no order which should receive an orderIndex,
 * and other subsequent columns should be shifted appropriately.
 * @param allColumns
 * @returns
 */
const updateColumnOrdering = (allColumns: OrderableColumn[]): OrderableColumn[] => {
    const columnOrderingHasBeenSet = allColumns.find((x) => Boolean(x.orderIndex))
    if (!columnOrderingHasBeenSet) {
        // no ordering on any columns right now, so don't add any
        return allColumns
    }

    const newlyShownColumn = allColumns.find((col) => col.hide !== true && col.orderIndex === undefined)
    if (!newlyShownColumn) {
        // unable to find the "newly added" column based on not being hidden and having no orderIndex.
        // This shouldn't happen, but if it does, just leave the columns as-is
        return allColumns
    }

    const visibleColumns = allColumns.filter((x) => x.hide !== true)
    const defaultIndexOfNewlyShownColumn = allColumns.indexOf(newlyShownColumn)
    const precedingVisibleColumns = visibleColumns.filter((x) => allColumns.indexOf(x) < defaultIndexOfNewlyShownColumn)
    const followingVisibleColumns = visibleColumns.filter((x) => allColumns.indexOf(x) > defaultIndexOfNewlyShownColumn)
    if (precedingVisibleColumns.length) {
        // put the new column after the highest orderIndex of columns that would normally go before this one
        const precedingOrderedColumn = orderBy(precedingVisibleColumns, ['orderIndex'], ['desc'])[0]
        newlyShownColumn.orderIndex = precedingOrderedColumn.orderIndex! + 1
    } else {
        // put this new column at the index of the lowest orderIndex of columns that would normally
        // go after this one
        const followingOrderedColumn = orderBy(followingVisibleColumns, ['orderIndex'], ['asc'])[0]
        newlyShownColumn.orderIndex = followingOrderedColumn.orderIndex!
    }

    // now that the column order has been set, bump up the orderIndex of all the following columns
    followingVisibleColumns.forEach((col) => {
        col.orderIndex! += 1
    })

    return allColumns
}

export {
    getMergedColumns,
    parseFilterFieldsFromGridDataState,
    DefaultFilter,
    handleFilterButtonClick,
    scrollToRow,
    updateColumnOrdering,
}
export default getOverriddenColumns
