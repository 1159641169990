import { GridCellProps } from '@progress/kendo-react-grid'
import { Form } from 'react-bootstrap'
import { ChangeEvent } from 'react'

const EditInPlaceTextBox = (
    props: GridCellProps,
    isInvalid: (dataItem: any) => boolean,
) => {
    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] || ''

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e,
                value: e.target.value,
            })
        }
    }

    const validationErrorBorder = isInvalid(dataItem) ? '1px solid red' : ''
    const customRendering =
        dataItem.inEdit === props.field ? (
            <td>
                <Form.Control
                    type="text"
                    value={dataValue}
                    onChange={onChange}
                    autoFocus
                />
            </td>
        ) : (
            <td style={{ border: validationErrorBorder }}>{dataValue.toString()}</td>
        )

    // since there is cell and row-level render overriding, we need to make use of that in this manner.
    return props.render ? props.render(customRendering, props) : customRendering
}

export default EditInPlaceTextBox
