import { Grid, GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { useMemo, useState } from 'react'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { isShiftNameInvalid, isShiftTypeInvalid, Shift } from 'types/Shifts'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import EditInPlaceComboBox from 'views/Common/Kendo/EditInPlaceComboBox'
import EditInPlaceDropdown from 'views/Common/Kendo/EditInPlaceDropdown'
import EditInPlaceTextBox from 'views/Common/Kendo/EditInPlaceTextBox'
import EditInPlaceTimepicker from 'views/Common/Kendo/EditInPlaceTimePicker'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import InfoIcon from 'views/Common/Widget/InfoIcon'

export type GridMode = 'Schedule' | 'Patterns' | 'Shifts'

/**
 * Get the column defintions for the grid
 * @param shiftNames
 * @returns
 */
const getColumns = (mode: GridMode, shiftNames: string[]): KendoGridColumn[] => [
    {
        title: '',
        field: '',
        cell: (props: GridCellProps) => {
            const shift = props.dataItem as Shift
            return (
                <td style={{ textOverflow: 'clip' }}>
                    {shift.sourceShiftId === 0 ? (
                        <InfoIcon tooltip="Not linked to a shift" icon="bi-info-circle" variant="text-primary" />
                    ) : (
                        <></>
                    )}
                </td>
            )
        },
        hide: mode === 'Shifts',
        width: '30px',
        editable: false,
    },
    {
        title: 'Date',
        field: 'calculatedScheduleDateString',
        width: '100px',
        editable: false,
        hide: mode !== 'Schedule',
    },
    {
        title: 'Day #',
        width: '90px',
        field: 'shiftDayNumber',
        editor: 'numeric',
        hide: mode === 'Shifts',
    },
    {
        title: 'Name',
        field: 'name',
        cell:
            mode !== 'Shifts'
                ? (props: GridCellProps) =>
                    EditInPlaceComboBox(
                        props,
                        'name',
                        shiftNames.map((shiftName) => ({
                            label: shiftName,
                            value: shiftName,
                        })),
                        (dataItem: any) => isShiftNameInvalid(dataItem as Shift),
                    )
                : (props: GridCellProps) =>
                    EditInPlaceTextBox(
                        props,
                        (dataItem: any) => isShiftNameInvalid(dataItem as Shift),
                    ),
    },
    {
        title: 'Start',
        field: 'startTime',
        cell: EditInPlaceTimepicker,
    },
    { title: 'End', field: 'calculatedEndTime', cell: EditInPlaceTimepicker },
    {
        title: 'Type',
        field: 'shiftType',
        cell: (props: GridCellProps) => {
            return EditInPlaceDropdown(
                props,
                [
                    { value: '', label: '' },
                    { value: 'Crewing', label: 'Crewing' },
                    { value: 'Non-Crewing', label: 'Non-Crewing' },
                    { value: 'Marker', label: 'Marker' },
                    { value: 'Critical-Marker', label: 'Critical-Marker' },
                    { value: 'Sleep', label: 'Sleep' },
                ],
                (dataItem: any) => isShiftTypeInvalid(dataItem as Shift),
            )
        },
    },
]

export interface EditableShift extends Shift {
    inEdit?: string
}

export interface ShiftGridProps {
    gridRef: React.RefObject<Grid>
    height: number
    mode: GridMode
    shifts: Shift[]
    scrollRowIndex?: number
    emptyGridText: string
    shiftSegments: Shift[]
    setShifts: (shifts: Shift[]) => void
    setShiftsCustomizedFlag?: () => void
    setSelectedShiftIds: (shiftIds: number[]) => void
}

/**
 * Grid component
 * @param param
 * @returns
 */
const ShiftsGrid = (shiftprops: ShiftGridProps) => {
    const {
        gridRef,
        height,
        shifts,
        mode,
        emptyGridText,
        shiftSegments,
        setShifts,
        setShiftsCustomizedFlag,
        setSelectedShiftIds,
        scrollRowIndex,
    } = shiftprops
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const editableShifts = shifts.map((x) => x as EditableShift)

    /**
     * Enter into Edit mode
     * @param dataItem
     * @param field
     */
    const enterEditHandler = (dataItem: any, field: string | undefined) => {
        const shiftItem = dataItem as Shift
        // set the appropriate item to be "inEdit"
        setShifts([
            ...editableShifts.map((shift) => ({
                ...shift,
                inEdit: shift.id === shiftItem.id ? field : undefined,
            })),
        ])
    }

    /**
     * Exit from Edit mode
     */
    const exitEditHandler = () => {
        setShifts([...editableShifts.map((shift) => ({ ...shift, inEdit: undefined }))])
    }

    /**
     * Editing has completed, so update the underlying value and apply additional logic as needed.
     * @param event
     */
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field
        const editedShift = event.dataItem as Shift
        if (
            !(
                field === 'name' ||
                field === 'startTime' ||
                field === 'calculatedEndTime' ||
                field === 'shiftType' ||
                field === 'shiftDayNumber'
            )
        ) {
            // not an editable field
            return
        }

        setShifts([
            ...editableShifts.map((shift) => {
                if (shift.id !== editedShift.id) {
                    // no changes to this one
                    return shift
                }

                if (
                    field === 'name' ||
                    field === 'startTime' ||
                    field === 'calculatedEndTime' ||
                    field === 'shiftType'
                ) {
                    shift[field] = event.value
                    if (field === 'calculatedEndTime' || field === 'startTime') {
                        // update the duration based on the changed start or end time
                        const startMinute = dateTimeFormatting.getMinutesOfDayFromTimeString(shift.startTime)
                        const endMinute = dateTimeFormatting.getMinutesOfDayFromTimeString(shift.calculatedEndTime)
                        let duration = endMinute - startMinute
                        if (duration < 0) {
                            // asuming end has passed midnight
                            duration += 1440
                        }
                        shift.duration = duration
                    }
                }

                if (field === 'shiftDayNumber') {
                    const dayNumber = parseInt(event.value)
                    if (!Number.isNaN(dayNumber) && dayNumber > 0) {
                        shift[field] = dayNumber
                    }
                }

                if (field === 'name') {
                    // user changed the name, so see if the shift is now linked to a configured Shift Segment
                    const segment = shiftSegments.find((x) => x.name === shift.name)
                    if (segment) {
                        // the user has matched up the shift name with an actual shift segment
                        shift.sourceShiftId = segment.id
                        shift.startTime = segment.startTime
                        shift.calculatedEndTime = segment.calculatedEndTime
                        shift.duration = segment.duration
                        shift.shiftType = segment.shiftType
                    } else {
                        // not linked
                        shift.sourceShiftId = 0
                    }
                } else {
                    // user changed something other than name, if all the values don't match a Shift Segment, then unlink by setting sourceShiftId = 0
                    const segment = shiftSegments.find(
                        (x) =>
                            x.name === shift.name &&
                            x.startTime === shift.startTime &&
                            x.duration === shift.duration &&
                            x.shiftType === shift.shiftType,
                    )
                    if (!segment) {
                        shift.sourceShiftId = 0
                    }
                }

                // if the user changes Name or Type via their dropdowns, immediately exit from edit mode
                if ((mode !== 'Shifts' && field === 'name') || field === 'shiftType') {
                    shift.inEdit = undefined
                }

                if (setShiftsCustomizedFlag) {
                    setShiftsCustomizedFlag()
                }
                return shift
            }),
        ])
    }

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => (
        <CellRender originalProps={props} td={td} enterEdit={enterEditHandler} editField="inEdit" />
    )

    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => (
        <RowRender originalProps={props} tr={tr} exitEdit={exitEditHandler} editField="inEdit" />
    )

    const columns = useMemo(() => {
        return getColumns(
            mode,
            shiftSegments.map((x) => x.name),
        )
    }, [mode, shiftSegments])

    return (
        <KendoGridCustom
            gridSelectionMode="multiple"// {shiftprops.mode === 'Shifts' ? 'single' : 'multiple'}
            gridRef={gridRef}
            sortable={shiftprops.mode === 'Shifts'}
            onItemChange={itemEditedHandler}
            cellRender={customCellRender}
            localStorageKeyForColumnState={shiftprops.mode === 'Shifts' ? 'shiftsGrid' : ''}
            localStorageKeyForGridDataState={shiftprops.mode === 'Shifts' ? 'shiftsGridDataState' : ''}
            rowRender={customRowRender}
            editField="inEdit"
            centeredContent
            defaultEmptyGridText={emptyGridText}
            defaultSort={false}
            filterable={false}
            pageable={false}
            height={`${height}px`}
            reorderable={false}
            data={editableShifts}
            columns={columns}
            selectedRowsState={selectedRowsState}
            onSetSelectedRowsState={(newState: SelectionState) => {
                setSelectedShiftIds(getSelectedIds(newState))
                setSelectedRowsState(newState)
            }}
            scrollToRowIndex={scrollRowIndex}
            setColumnVisibility={() => {}}
        />
    )
}

export default ShiftsGrid
