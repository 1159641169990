import Scenario, { parseScenario } from './Scenario'

export const parseReportConfigMetadata = (data: any) => {
    const mapDataItemDataType = (dataType: string) => {
        let type: ReportItemDefinitionDataType
        switch (dataType.toLowerCase()) {
            case 'string':
                type = 'string'
                break
            case 'boolean':
                type = 'boolean'
                break
            case 'single':
                type = 'single'
                break
            case 'double':
                type = 'double'
                break
            case 'integer':
                type = 'integer'
                break
            case 'int32':
                type = 'int32'
                break
            case 'datetime':
                type = 'datetime'
                break
            case 'date':
                type = 'date'
                break
            case 'time':
                type = 'time'
                break
            default:
                throw Error('unknown ItemDefinitionDataType:' + dataType)
        }
        return type
    }

    const parsed = data as ReportingMetadata
    parsed.seriesDataItems.events = parsed.seriesDataItems.events.map((x) => {
        return { ...x, type: mapDataItemDataType(x.type) }
    })
    parsed.seriesDataItems.schedules = parsed.seriesDataItems.schedules.map((x) => {
        return { ...x, type: mapDataItemDataType(x.type) }
    })
    parsed.scenarios = parsed.scenarios.map(parseScenario)
    return parsed
}

interface ReportingMetadata {
    binSizes: IdLabelSet[]
    xAxisGroupings: IdLabelSet[]
    seriesDataItems: XAxisItems
    drilldownGridColumns: DrilldownGridColumns
    colorPalette: string[]
    unusedPaletteColors: string[]
    scenarios: Scenario[]
}

interface DrilldownGridColumns {
    events: DrilldownColumn[]
    schedules: DrilldownColumn[]
}

interface DrilldownColumn {
    field: string
    title: string
    hidden: boolean
    width?: number
    isNumeric: boolean
    palette: string
    isCrewingOnlyMetric?: boolean
    dataCategory?: string
    format?: string
    type?: string
}

export interface XAxisItems {
    events: ReportItemDefinition[]
    schedules: ReportItemDefinition[]
}

export type ReportItemDefinitionDataType =
    | 'string'
    | 'boolean'
    | 'single'
    | 'double'
    | 'integer'
    | 'int32'
    | 'datetime'
    | 'date'
    | 'time'

export interface ReportItemDefinition {
    dataItem: string
    displayName: string
    type: ReportItemDefinitionDataType
    category: string
}

export interface IdLabelSet {
    id: string | number
    label: string
}

export default ReportingMetadata
