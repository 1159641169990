import { Grid } from '@progress/kendo-react-grid'
import { useRef, useState } from 'react'
import { getLowestId } from 'services/utilities/arrayUtils'
import { scrollToRow } from 'services/utilities/kendoGridUtils'
import { createShift, Shift } from 'types/Shifts'
import IconButton from 'views/Common/Buttons/IconButton'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import ShiftsGrid, { EditableShift } from './ShiftsGrid'

type ShownDialogType = 'None' | 'ConfirmDeleteShift'

const ShiftsTab = (props: { shifts: Shift[]; setDialogShifts(shifts: Shift[]): void }) => {
    const gridRef = useRef<Grid>(null)
    const [shiftsGridSelectedIds, setShiftsGridSelectedIds] = useState<number[]>([])
    const shifts = [...props.shifts]
    const addShiftClickHandler = () => {
        const shiftId = getLowestId(shifts) - 1
        const newShift: EditableShift = createShift(shiftId, 0);
        newShift.name = 'New Shift'
        newShift.inEdit = 'name'
        props.setDialogShifts([...shifts, newShift])
        scrollToRow(gridRef, shifts.length)
    }

    const copyShiftClickHandler = () => {
        if (shiftsGridSelectedIds.length === 0) {
            return
        }

        const selectedShift = shifts.find((x) => x.id === shiftsGridSelectedIds[0])!
        const shiftId = getLowestId(shifts) - 1
        const shiftCopy = { ...selectedShift, name: `${selectedShift.name} - copy`, id: shiftId, inEdit: 'name' }
        props.setDialogShifts([...shifts, shiftCopy])
        scrollToRow(gridRef, shifts.length)
    }

    const [showDialog, setShowDialog] = useState<ShownDialogType>('None')

    const deleteShiftClickHandler = () => {
        if (shiftsGridSelectedIds.length === 0) {
            return
        }
        setShowDialog('ConfirmDeleteShift')
    }

    return (
        <>
            {showDialog === 'ConfirmDeleteShift' && (
                <ConfirmationDialog
                    headerText="Confirm Delete Shift"
                    closeCallback={() => {
                        setShowDialog('None')
                    }}
                    confirmedCallback={() => {
                        setShowDialog('None')
                        // Delete the shift based on selected row in grid
                        props.setDialogShifts([...shifts.filter((x) => !shiftsGridSelectedIds.includes(x.id))])
                    }}
                >
                    <>
                        <p>
                            Deleting this Shift will unlink it from any Patterns or Shift Schedules where it has been
                            used.
                        </p>
                    </>
                </ConfirmationDialog>
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '5px',
                    alignItems: 'flex-end',
                }}
            >
                <FormLabelCustom style={{ marginBottom: '0px' }}>Shifts</FormLabelCustom>
                <div>
                    <IconButton tooltip="Add Shift" onClick={addShiftClickHandler} icon="bi-file-plus" />
                    <IconButton
                        tooltip="Copy Shift"
                        onClick={copyShiftClickHandler}
                        disabled={shiftsGridSelectedIds.length === 0}
                        toolbarLeftMargin
                        icon="bi-files"
                    />
                    <IconButton
                        tooltip="Delete Shift"
                        onClick={deleteShiftClickHandler}
                        disabled={shiftsGridSelectedIds.length === 0}
                        toolbarLeftMargin
                        icon="bi-trash"
                    />
                </div>
            </div>
            <ShiftsGrid
                gridRef={gridRef}
                height={610}
                mode="Shifts"
                emptyGridText="Click 'Add' to create a new Shift segment"
                shifts={shifts}
                shiftSegments={[]}
                setShifts={props.setDialogShifts}
                setSelectedShiftIds={setShiftsGridSelectedIds}
            />
        </>
    )
}

export default ShiftsTab
